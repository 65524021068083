import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import NdisPage from "views/NdisPage/NdisPage.js";
import ScrollToTop from "ScrollToTop.js"

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <ScrollToTop>
      <Switch>
        <Route path="/ndis" component={NdisPage} />
        <Route path="/" component={LandingPage} />
      </Switch>
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);
