import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { container } from "assets/jss/material-kit-react.js";

// @material-ui/icons
import Control from "@material-ui/icons/Check";
import Choice from "@material-ui/icons/FindInPage";
import Respect from "@material-ui/icons/SupervisedUserCircle";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";


import image1 from "assets/carousel/img1.jpg";
import image2 from "assets/carousel/img2.jpg";
import image3 from "assets/carousel/img3.jpg";
import choice from "assets/img/icons/choice.png";


import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import carouselStyles from  "assets/jss/material-kit-react/views/components.js";

import Carousel from "react-slick";

const useStyles = makeStyles(styles);

const carouselStyle = {
  carousel: {
    padding: "70px 0"
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  }
};

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center" width="100vw">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Working with you to help you bring your goals to life.</h2>
            

          <h5 className={classes.description} style={{fontSize: "1.125rem"}}>
          Whatever your NDIS goals are, at Aim High we will help you achieve them -  
          Whether it's developing new skills, participating in the community, 
          becoming more independent, or accessing the services you need.
          We are here to support you to live your best life on your own terms.
          </h5>
        </GridItem>
      </GridContainer>

      <Card carousel>
              <Carousel>
                <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                </div>
                <div>
                  <img src={image2} alt="Second slide" className="slick-image"
                  />
                </div>
                <div>
                  <img src={image3} alt="Third slide" className="slick-image" />
                </div>
              </Carousel>
            </Card>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Choice"
              description="NDIS values the clients choice, it aims to provide each individual the right to access the necessary support and service to achieve their goal."
              iconColor="info"
              icon={Choice}
              vertical
              fontSize="16px"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Respect"
              description="Aim High has a strong commitment to equality, diversity and inclusion, and will demonstrate respect and integrity at all times."
              icon={Respect}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Control"
              description="The NDIS puts choice and control in the hands of people with disability, from goal planning to delivery of supports."
              icon={Control}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
