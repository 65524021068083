import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { container } from "assets/jss/material-kit-react.js";

// @material-ui/icons
import Accessible from "@material-ui/icons/Accessibility";
import Choice from "@material-ui/icons/FindInPage";
import Respect from "@material-ui/icons/SupervisedUserCircle";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";


import image1 from "assets/carousel/img1.jpg";
import image2 from "assets/carousel/img2.jpg";
import image3 from "assets/carousel/img3.jpg";
import choice from "assets/img/icons/choice.png";
import logo from "assets/img/ndis.svg";


import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import carouselStyles from  "assets/jss/material-kit-react/views/components.js";

import Carousel from "react-slick";

const useStyles = makeStyles(styles);

const carouselStyle = {
  carousel: {
    padding: "70px 0"
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  }
};

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center" width="100vw">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Helping you with funding for supports and services.</h2>
            

          <h5 className={classes.description} style={{fontSize: "1.125rem"}}>
          The NDIS provides full funding for any "reasonable and neccessary" supports that are related to their disability. 
          It is then up to the individual how to use their supports. At Aim High, we specialise in providing you with the
          supports you need to gelp you get the most out of the NDIS, and to help you live your best life.
          </h5>
        </GridItem>
      </GridContainer>


      <img src={logo} alt="Logo" style={{maxWidth:"200px", padding: "30px"}}/>

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Eligibilty"
              description="To find out if you are eligible you will need to complete a checklist and apply through NDIS on their website at https://www.ndis.gov.au/"
              iconColor="info"
              icon={Choice}
              vertical
              fontSize="16px"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Arranging Services"
              description="Our team are experts at providing you with the services you need. Call us anytime to see how we can help you get the support you need."
              icon={Respect}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Choice"
              description="The NDIS puts choice and control in the hands of people with disability, from goal planning to delivery of supports.
              At the end of the day, it's up to you."
              icon={Accessible}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
