import React from "react";
import { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Form Style
import "./style.css";

// Custom hook
import { useInput } from "hooks/input-hook";

import { useHistory } from "react-router-dom";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection(props) {
  // Use Styles
  const classes = useStyles();

  // Form
  const { value, bind, reset } = useInput("");

  const { value: name, bind: bindName, reset: resetName } = useInput("");
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput("");

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contact us</h2>
          <h4 className={classes.description}>
            <h3>
              <span style={{ fontWeight: "bold" }}>Phone: </span>
              <span>02 9896 0368</span>
            </h3>
            <h3>
              <span style={{ fontWeight: "bold" }}>Address: </span>
              <span>298 Prairie Vale Road, Prairiewood</span>
            </h3>
            <h3>
              <span style={{ fontWeight: "bold" }}>Email: </span>
              <span style={{ flex: 1, flexWrap: "wrap" }}>aimhighndisservices&#8203;@outlook.com</span>
            </h3>
            Or fill out the form below and we will get in touch with you.
          </h4>

          <form class="css-form" action="https://submit-form.com/_JFN91H272ZWOKFFJBwLE" method="post">
            <label>
              Name:
              <input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} type="text" name="name" {...bindName} />
            </label>
            <label>
              Email:
              <input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} type="text" name="email" {...bindEmail} />
            </label>
            <label>
              Message:
              <textarea name="message" {...bindMessage} />
            </label>
            <input type="submit" value="Submit" />
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
