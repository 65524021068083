import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import personalcare from "assets/img/icons/personalcare.png";
import householdtasks from "assets/img/icons/householdtasks.png";
import travel from "assets/img/icons/travel.png";
import earlychildhood from "assets/img/icons/earlychildhood.png";
import community from "assets/img/icons/community.png";
import plan from "assets/img/icons/plan.png";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Services We Provide</h2>
      <div>
        <GridContainer>
          
          
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
            <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={personalcare} alt="..." className={imageClasses} padding="30px"/>
            </GridItem>  
              <h4 className={classes.cardTitle}>
                Personal Care
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Personal Assistance in the Home or Community
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={householdtasks} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Household Tasks
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Helping around the house (Cleaning, Cooking, and other household duties)
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={travel} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Travel &amp; Transport
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Help getting you where you need to go
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={earlychildhood} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Early Childhood
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Care for younger children
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={community} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Community Assistance
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Community participation and Engagement
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={plan} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Plan Management
                <br />
              </h4>
              <CardBody>
                <p className={classes.description}>
                  NDIS Care Plan Management
                </p>
              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>
      </div>
    </div>
  );
}
